@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Rubik:wght@400;500&display=swap');

* {
    font-family: 'Roboto', sans-serif;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-style: normal;
}
/* @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), 
        local('./Roboto/Roboto-Regular'),
        url('./Roboto/Roboto.woff2') format('woff2'), 
        url('./Roboto/Roboto.woff') format('woff'), 
        url('./Roboto/Roboto.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
} */

/* @font-face {
    font-family: 'Rubik';
    src: local('Rubik'), 
        local('./Rubik/Rubik-Regular'), 
        url('./Rubik/Rubik.woff2') format('woff2'), 
        url('./Rubik/Rubik.woff') format('woff'), 
        url('./Rubik/Rubik.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
} */